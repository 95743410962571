















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import RecordedLectureFormTemplate from '@/components/resources/RecordedLectureFormTemplate.vue';
import { CourseStores } from '@/store/course';
import { DropdownItem } from '@/models/dropdown';
import { CreateLecture } from '@/models/lectures';
import { LectureStores } from '@/store/lectures';
@Component({
    components: { Page, BackButtonLayout, RecordedLectureFormTemplate },
})
export default class CreateResources extends Vue {
    // Stores
    courseListStore = CourseStores.list;
    lectureListStore = LectureStores.list;
    lectureStateStore = LectureStores.state;
    lectureCreateStore = LectureStores.create;
    // UI State
    courseData: DropdownItem[] = [];
    selectedCourseData: DropdownItem = {
        text: ``,
        value: ``,
        uniqueKey: ``
    };
    readingMaterialFolderData: DropdownItem[] = [];
    // Autofill
    readingMaterialCoursePrefixId = -1;
    readingMaterialFolderName = ``;

    get layoutTitle() {
        return `Add New Recordings`;
    }

    get backRoute() {
        return `/resources/lectures`;
    }

    get courses() {
        return this.courseData;
    }

    get folders() {
        return this.readingMaterialFolderData;
    }

    mounted() {
        if (this.lectureStateStore.lectureFolderState.length === 3) {
            this.readingMaterialCoursePrefixId = this.lectureStateStore.lectureFolderState[this.lectureStateStore.lectureFolderState.length - 2].id;
            this.readingMaterialFolderName = this.lectureStateStore.lectureFolderState[this.lectureStateStore.lectureFolderState.length - 1].name;
        }

        if (this.lectureStateStore.lectureFolderState.length === 2) {
            this.readingMaterialCoursePrefixId = this.lectureStateStore.lectureFolderState[this.lectureStateStore.lectureFolderState.length - 1].id;
        }
        this.readingMaterialFolderData.push({
            text: `- Select -`,
            value: ``,
            uniqueKey: `select`,
        });
        this.courseListStore.retrieveCoursesWithParams({
            limit: 100,
        });
    }

    fetchFolderLevels(payload: DropdownItem) {
        this.selectedCourseData = payload;
        // this.lectureListStore.retrieveReadingMaterialLevelsWithParams({ courseid: Number(payload.value)});
    }

    @Watch(`courseListStore.response`)
    updateCourseDropdownData() {
        if (this.courseListStore.response) {
            this.courseData = [];
            this.courseListStore.response.objects.forEach((data) => {
                this.courseData.push({
                    text: data.courseName,
                    value: data.id.toString(),
                    uniqueKey: `course_${data.courseName}`
                });
            });
        }
    }

    @Watch(`lectureListStore.levelResponse`)
    updateReadingMaterialDropdownData() {
        // if (this.lectureListStore.levelResponse) {
        //     this.readingMaterialFolderData = [];
        //     this.readingMaterialFolderData.push({
        //         text: `- Select -`,
        //         value: ``,
        //         uniqueKey: `select`,
        //     });
        //     this.lectureListStore.levelResponse.objects.forEach((data, index) => {
        //         this.readingMaterialFolderData.push({
        //             text: data,
        //             value: data,
        //             uniqueKey: `folder_name_${index}`
        //         });
        //     });
        // }
    }

    createLecture(payload: CreateLecture) {
        const formData = new FormData();
        if (typeof payload.thumbnail !== 'string' && payload.thumbnail ) {
            formData.append(`file`, payload.thumbnail);
        }
        formData.append(`fileName`, payload.fileName);
        formData.append(`courseId`, payload.courseId.toString());
        formData.append(`recordingUrl`, payload.recordingUrl);
        formData.append(`normalPrice`, payload.normalPrice);
        formData.append(`discountPrice`, payload.discountPrice);
        formData.append(`duration`, '0'); // TODO: Fix the variable after confirm
        formData.append(`recordedId`, '0'); // TODO: Fix the variable after confirm
        
        if (payload.availability !== null) {
            payload.availability === true ? formData.append(`availability`, 'true'): formData.append(`availability`, 'false');
        }

        this.lectureCreateStore.createLecture(formData);
    }

    @Watch(`lectureCreateStore.response`)
    redirectToResources() {
        if (this.lectureCreateStore.response) {
            // this.lectureStateStore.updateReadingMaterialIsModified(true);
            this.$router.push({
                path: `/resources/lectures`
            });
        }
    }
}
